import React from 'react'
import BranchHomePage from '../components/ourBranchesHome'

const OurBranches = () => {
  return (
    <div>
      <BranchHomePage />
    </div>
  )
}

export default OurBranches;