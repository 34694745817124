import React from 'react';

// Sample data for downloadable files
const downloads = [
  { id: 1, name: 'Document 1', url: '/files/document1.pdf' },
  { id: 2, name: 'Document 2', url: '/files/document2.pdf' },
  { id: 3, name: 'Document 3', url: '/files/document3.pdf' },
  // Add more files as needed
];

const Downloads = () => {
  return (
    <div className="container mx-auto p-6 max-w-7xl">
      <header className="text-center mb-6">
        <h1 className="text-4xl font-bold mb-4">Downloads</h1>
        <p className="text-lg text-gray-700">
          Here you can find all the downloadable files available for you.
        </p>
      </header>

      <section>
        <ul className="space-y-4">
          {downloads.map((file) => (
            <li key={file.id} className="bg-white p-4 rounded-lg shadow-md flex items-center justify-between">
              <span className="text-lg font-semibold">{file.name}</span>
              <a
                href={file.url}
                download
                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors duration-300"
              >
                Download
              </a>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default Downloads;
