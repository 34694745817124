import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

const AmericaTabs = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <nav className="bg-white border-b m-4 border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative z-0 flex flex-col sm:flex-row sm:text-center justify-center space-y-2 sm:space-y-0 sm:space-x-4">
          <ul className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
            <li>
              <Link
                to="/study-abroad/united-states-of-america"
                className={classNames(
                  'py-2 px-4 text-sm font-medium text-center',
                  {
                    'text-blue-500 border-b-2 border-blue-500': currentPath === '/study-abroad/united-states-of-america',
                    'text-gray-500': currentPath !== '/study-abroad/united-states-of-america',
                  }
                )}
              >
                Overview
              </Link>
            </li>
            <li>
              <Link
                to="/study-abroad/united-states-of-america/life-and-study"
                className={classNames(
                  'py-2 px-4 text-sm font-medium text-center',
                  {
                    'text-blue-500 border-b-2 border-blue-500': currentPath === '/study-abroad/united-states-of-america/life-and-study',
                    'text-gray-500': currentPath !== '/study-abroad/united-states-of-america/life-and-study',
                  }
                )}
              >
                Life at America
              </Link>
            </li>
            <li>
              <Link
                to="/study-abroad/united-states-of-america/universities"
                className={classNames(
                  'py-2 px-4 text-sm font-medium text-center',
                  {
                    'text-blue-500 border-b-2 border-blue-500': currentPath === '/study-abroad/united-states-of-america/universities',
                    'text-gray-500': currentPath !== '/study-abroad/united-states-of-america/universities',
                  }
                )}
              >
                Universities
              </Link>
            </li>
            <li>
              <Link
                to="/study-abroad/united-states-of-america/scholarships"
                className={classNames(
                  'py-2 px-4 text-sm font-medium text-center',
                  {
                    'text-blue-500 border-b-2 border-blue-500': currentPath === '/study-abroad/united-states-of-america/scholarships',
                    'text-gray-500': currentPath !== '/study-abroad/united-states-of-america/scholarships',
                  }
                )}
              >
                Scholarships
              </Link>
            </li>
            <li>
              <Link
                to="/study-abroad/united-states-of-america/visa-and-processing"
                className={classNames(
                  'py-2 px-4 text-sm font-medium text-center',
                  {
                    'text-blue-500 border-b-2 border-blue-500': currentPath === '/study-abroad/united-states-of-america/visa-and-processing',
                    'text-gray-500': currentPath !== '/study-abroad/united-states-of-america/visa-and-processing',
                  }
                )}
              >
                Visa and Processing 
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default AmericaTabs;
