import React from 'react'

function ScholarshipsAtCanada() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">Scholarships in Canada for International Students</h1>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Types of Scholarships</h2>
        <ul className="list-disc pl-5 text-lg text-gray-700">
          <li className="mb-2">
            <strong>Merit-Based Scholarships:</strong> Awarded based on academic performance, leadership skills, and extracurricular achievements.
          </li>
          <li className="mb-2">
            <strong>Need-Based Scholarships:</strong> Provided to students who demonstrate financial need to help cover their educational expenses.
          </li>
          <li className="mb-2">
            <strong>Program-Specific Scholarships:</strong> Offered for specific fields of study or academic programs, such as engineering or business.
          </li>
          <li className="mb-2">
            <strong>Country-Specific Scholarships:</strong> Targeted at students from particular countries or regions, often funded by governments or international organizations.
          </li>
          <li className="mb-2">
            <strong>Research Grants:</strong> Available for students undertaking research projects or pursuing postgraduate studies.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Top Scholarships for International Students</h2>
        <ul className="list-disc pl-5 text-lg text-gray-700">
          <li className="mb-2">
            <strong>Vanier Canada Graduate Scholarships:</strong> Provides significant funding for doctoral students.
          </li>
          <li className="mb-2">
            <strong>Canada Graduate Scholarships - Master's Program:</strong> Offers support for students pursuing a master’s degree.
          </li>
          <li className="mb-2">
            <strong>International Scholarships at Canadian Universities:</strong> Various universities offer scholarships specifically for international students.
          </li>
          <li className="mb-2">
            <strong>Queen Elizabeth II Diamond Jubilee Scholarships:</strong> Provides funding for international graduate students and researchers.
          </li>
          <li className="mb-2">
            <strong>Canadian Commonwealth Scholarship Program:</strong> Offers scholarships to students from Commonwealth countries.
          </li>
        </ul>
      </section>
    </div>
  )
}

export default ScholarshipsAtCanada