import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

const UKTabs = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <nav className="bg-white border-b m-4 border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative z-0 flex justify-center space-x-4">
          <ul className="flex space-x-4">
            <li>
              <Link
                to="/study-abroad/united-kingdom"
                className={classNames(
                  'py-2 px-4 text-sm font-medium',
                  {
                    'text-blue-500 border-b-2 border-blue-500': currentPath === '/study-abroad/united-kingdom',
                    'text-gray-500': currentPath !== '/study-abroad/united-kingdom',
                  }
                )}
              >
                Overview
              </Link>
            </li>
            <li>
              <Link
                to="/study-abroad/united-kingdom/life-and-study"
                className={classNames(
                  'py-2 px-4 text-sm font-medium',
                  {
                    'text-blue-500 border-b-2 border-blue-500': currentPath === '/study-abroad/united-kingdom/life-and-study',
                    'text-gray-500': currentPath !== '/study-abroad/united-kingdom/life-and-study',
                  }
                )}
              >
                Life at United Kingdom
              </Link>
            </li>
            <li>
              <Link
                to="/study-abroad/united-kingdom/universities"
                className={classNames(
                  'py-2 px-4 text-sm font-medium',
                  {
                    'text-blue-500 border-b-2 border-blue-500': currentPath === '/study-abroad/united-kingdom/universities',
                    'text-gray-500': currentPath !== '/study-abroad/united-kingdom/universities',
                  }
                )}
              >
                Universities
              </Link>
            </li>
            <li>
              <Link
                to="/study-abroad/united-kingdom/scholarships"
                className={classNames(
                  'py-2 px-4 text-sm font-medium',
                  {
                    'text-blue-500 border-b-2 border-blue-500': currentPath === '/study-abroad/united-kingdom/scholarships',
                    'text-gray-500': currentPath !== '/study-abroad/united-kingdom/scholarships',
                  }
                )}
              >
                Scholarships
              </Link>
            </li>
            <li>
              <Link
                to="/study-abroad/united-kingdom/visa-and-processing"
                className={classNames(
                  'py-2 px-4 text-sm font-medium',
                  {
                    'text-blue-500 border-b-2 border-blue-500': currentPath === '/study-abroad/united-kingdom/visa-and-processing',
                    'text-gray-500': currentPath !== '/study-abroad/united-kingdom/visa-and-processing',
                  }
                )}
              >
                Visa and Processing
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default UKTabs;
