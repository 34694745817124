import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FAQdata } from '../data/FAQdata'; // Adjust the path based on your file structure

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleShowMore = () => {
    setShowAll(!showAll);
  };

  const displayedFAQ = showAll ? FAQdata : FAQdata.slice(0, 4);

  return (
    <div className="bg-gray-100 p-8">
      <h1 className="text-3xl font-bold mb-6">Frequently Asked Questions (FAQ)</h1>
      
      <div className="space-y-4">
        {displayedFAQ.map((faq, index) => (
          <div key={faq.id} className="bg-white p-4 rounded shadow-md">
            <button
              onClick={() => handleToggle(index)}
              className="w-full text-left font-semibold text-lg flex items-center justify-between"
            >
              {faq.question}
              <span>{activeIndex === index ? '-' : '+'}</span>
            </button>
            <CSSTransition
              in={activeIndex === index}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <p className="mt-2 text-lg">{faq.answer}</p>
            </CSSTransition>
          </div>
        ))}
      </div>

      <button
        onClick={toggleShowMore}
        className="mt-6 px-4 py-2 bg-[#eb4934] text-white font-semibold rounded hover:bg-[#d43b2e] transition"
      >
        {showAll ? 'Show Less' : 'Show More'}
      </button>
    </div>
  );
};

export default FAQ;
