import React, { useState } from 'react';
import Destinations from './../../../data/destinationdata'; // Ensure this path is correct

const UniversitiesAtEurope = () => {
  // Find the destination object for the Europe
  const europe = Destinations.find(dest => dest.name === "Europe");

  // Sort universities by rank
  const sortedUniversities = europe.universities.sort((a, b) => {
    const rankA = parseInt(a.rank.split(' ')[0].replace('th', '').replace('st', '').replace('nd', '').replace('rd', ''), 10);
    const rankB = parseInt(b.rank.split(' ')[0].replace('th', '').replace('st', '').replace('nd', '').replace('rd', ''), 10);
    return rankA - rankB;
  });

  // Pagination settings
  const itemsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(sortedUniversities.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentUniversities = sortedUniversities.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">Universities</h1>
        <p className="text-center text-lg text-gray-700 mb-12">{europe.description}</p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 relative">
          {currentUniversities.map((university, index) => (
            <div key={index} className="bg-white min-h-[240px] shadow-md rounded-lg overflow-hidden relative">
              <div className="p-6">
                <h2 className="text-xl font-semibold text-gray-900">{university.name}</h2>
                <p className="mt-2 text-sm text-gray-600">Rank: {university.rank}</p>
                <p className="mt-2 text-sm text-gray-600">Location: {university.location}</p>
              </div>
              <div className="p-6 absolute w-full bottom-0">
                <a href={university.link} target="_blank" rel="noopener noreferrer">
                  <button className="w-full py-2 bg-[#01132b] text-white font-semibold rounded-lg hover:bg-[#020c3c] transition-colors duration-300">
                    Visit University Website
                  </button>
                </a>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="mt-8 flex justify-center">
          <nav aria-label="Page navigation">
            <ul className="inline-flex -space-x-px">
              <li>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100"
                >
                  Previous
                </button>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`px-3 py-2 text-sm font-medium ${currentPage === index + 1 ? 'text-blue-600 bg-blue-100' : 'text-gray-500 bg-white'} border border-gray-300 hover:bg-gray-100`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100"
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default UniversitiesAtEurope;
