import React from 'react';

const TOEFLInfo = () => {
    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
            <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">
                About the TOEFL Exam
            </h1>
            <p className="text-gray-700 mb-4">
                The Test of English as a Foreign Language (TOEFL) is a standardized test that evaluates English language proficiency for non-native speakers.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">
                Test Format
            </h2>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Reading: 60-80 minutes</li>
                <li>Listening: 60-90 minutes</li>
                <li>Speaking: 20 minutes</li>
                <li>Writing: 50 minutes</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">
                Scoring System
            </h2>
            <p className="text-gray-700 mb-4">
                Scores range from 0 to 120, combining the four sections.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">
                Preparing for the TOEFL
            </h2>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Practice with TOEFL sample questions.</li>
                <li>Improve your academic English skills.</li>
                <li>Consider enrolling in a preparation course.</li>
            </ul>
        </div>
    );
};

export default TOEFLInfo;
