import React from 'react'

function LifeAtNewZealand() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">Life at New Zealand</h1>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Cultural Diversity</h2>
        <p className="text-lg text-gray-700">
          New Zealand is known for its friendly and diverse culture. With a rich Maori heritage and a welcoming attitude towards international students, the country offers a vibrant and inclusive environment for students from around the world.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Student Life</h2>
        <p className="text-lg text-gray-700">
          Student life in New Zealand is dynamic, with a range of activities and organizations available. Universities offer various clubs, sports, and social events, allowing students to connect with others and explore their interests outside of academics.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Accommodation</h2>
        <p className="text-lg text-gray-700">
          Students in New Zealand have several accommodation options, including on-campus residences, private rentals, and shared houses. It’s advisable to start looking for accommodation early and choose an option that suits your budget and lifestyle.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Healthcare</h2>
        <p className="text-lg text-gray-700">
          New Zealand provides excellent healthcare services. International students are required to have health insurance for the duration of their stay, which can be arranged through their university or a private provider.
        </p>
      </section>

      <section>
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Weather and Lifestyle</h2>
        <p className="text-lg text-gray-700">
          New Zealand's climate varies by region, with warmer temperatures in the north and cooler temperatures in the south. The country offers a high quality of life with outdoor activities, stunning landscapes, and a relaxed lifestyle.
        </p>
      </section>
    </div>
  );
};

export default LifeAtNewZealand