import React from 'react'

function LifeAtEurope() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">Life at Europe</h1>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Cultural Diversity</h2>
        <p className="text-lg text-gray-700">
          Europe is known for its rich cultural diversity and history. Each country offers unique cultural experiences, festivals, and traditions, providing international students with a broad and enriching experience.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Student Life</h2>
        <p className="text-lg text-gray-700">
          Student life across Europe is vibrant and varied. Universities host numerous clubs, societies, and events. Many cities also offer a lively cultural scene with theaters, museums, and music festivals.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Accommodation</h2>
        <p className="text-lg text-gray-700">
          Accommodation options in Europe include university dormitories, private rentals, and shared apartments. Costs and availability vary by city and country, so it's important to start searching early and choose a living arrangement that suits your needs.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Healthcare</h2>
        <p className="text-lg text-gray-700">
          Many European countries provide excellent healthcare services. As an international student, you may need to obtain health insurance or register with local healthcare providers. Coverage and requirements vary by country.
        </p>
      </section>

      <section>
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Weather and Lifestyle</h2>
        <p className="text-lg text-gray-700">
          Europe's weather varies significantly from country to country. Students can experience everything from Mediterranean warmth to Scandinavian cold. The diverse climate and lifestyle options cater to a wide range of preferences.
        </p>
      </section>
    </div>
  );
};

export default LifeAtEurope;