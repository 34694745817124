import React from 'react';

const SATInfo = () => {
    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
            <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">About the SAT Exam</h1>
            <p className="text-gray-700 mb-4">
                The SAT is a standardized test widely used for college admissions in the United States. It assesses a student's readiness for college and provides colleges with a common measure for comparing applicants.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Test Format</h2>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Evidence-Based Reading and Writing: 2 sections</li>
                <li>Math: 2 sections</li>
                <li>Optional Essay: 50 minutes</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Scoring System</h2>
            <p className="text-gray-700 mb-4">
                Scores range from 400 to 1600, combining results from the two sections.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Preparing for the SAT</h2>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Practice with official SAT materials.</li>
                <li>Take full-length practice tests.</li>
                <li>Review test-taking strategies.</li>
            </ul>
        </div>
    );
};

export default SATInfo;
