import { Link } from "react-router-dom";
import { FaAngleUp } from "react-icons/fa";
import AdvertisementBanner from "./footAdvertisment";

const Footer = () => {
  return (
    <footer className="bg-[#01132b] text-white w-full mx-auto pt-5">
      <div className="w-full max-w-[1200px] mx-auto border-b-2 border-white p-4">
        <AdvertisementBanner />
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.4521332813606!2d85.31992117525361!3d27.70332317618483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19db9433c02b%3A0x813abb6022393017!2sAAEC%20GLOBAL!5e0!3m2!1sen!2snp!4v1722395365362!5m2!1sen!2snp"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className="w-full max-w-[1200px] mx-auto flex flex-wrap justify-between text-center py-5">
        <div className="flex-1 mb-4">
          <Link to="https://www.aaecglobal.com">
            <img className="h-20 w-36 mx-auto" src={require("./images/logo.png")} alt="logo" />
          </Link>
          <h4 className="text-xl mb-2">AAEC Global Educational Consultancy Pvt. Ltd.</h4>
          <p className="text-sm">
            We are one of the leading providers of educational consultancy services in Nepal
            for aspirants who are willing to study abroad. Our educational consultants in Nepal
            have expertise in offering counseling to aspirants.
          </p>
        </div>
        <div className="flex-1 mb-4">
          <ul className="space-y-2 text-center">
            <li>
              <Link to="/study-abroad/united-kingdom" className="hover:underline">
                Study In Uk
              </Link>
            </li>
            <li>
              <Link to="/study-abroad/united-states-of-america" className="hover:underline">
                Study In Usa
              </Link>
            </li>
            <li>
              <Link to="/study-abroad/australia/" className="hover:underline">
                Study In Australia
              </Link>
            </li>
            <li>
              <Link to="/study-abroad/canada/" className="hover:underline">
                Study In Canada
              </Link>
            </li>
            <li>
              <Link to="/study-abroad/new-zealand/" className="hover:underline">
                Study In New Zealand
              </Link>
            </li>
            <li>
              <Link to="/study-abroad/europe/" className="hover:underline">
                Study In Europe
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex-1 mb-4">
          <ul className="space-y-2">
            <li>
              <Link to="/" className="hover:underline">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about-us" className="hover:underline">
                About Us
              </Link>
            </li>
            <li>
              <Link to="/contact" className="hover:underline">
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/our-branches" className="hover:underline">
                Our Branches
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="text-center py-3 text-xs">
        © AAEC Global - 2024. All rights reserved. Powered by & IT Partner: Axor Technology Private Limited
      </div>

      <button
        id="scroll-top"
        title="Go to Top"
        className="fixed bottom-5 right-5 hidden bg-blue-500 text-white rounded-full w-12 h-12 text-2xl shadow-md hover:bg-blue-700 transition-colors"
      >
        <FaAngleUp />
      </button>
    </footer>
  );
};

export default Footer;
