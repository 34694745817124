import React from "react";
import ControlledCarousel from "../components/carousel.jsx";
import TestPrep from "../components/testPrep.jsx";
import Destination from "../components/destination.jsx" ;
import BranchGridHome from "../components/branchGrid.jsx";
import EducationalConsultancy from "../components/homeAboutUs.jsx";
import FAQ  from "../components/homeFAQ.jsx";

function Home() {
  return (
    <div>
      <ControlledCarousel />
      <div className="px-4 py-6 max-w-7xl mx-auto  mx-auto">
        <h2 className="text-2xl font-bold mb-6">
          Leading Study Abroad Consultants in Kathmandu, Nepal
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">
              We help you to prepare all your documents
            </h3>
            <ul className="list-decimal list-inside">
              <li>
                <a href="#" className="text-blue-500 hover:underline">
                  Financial Documents
                </a>
              </li>
              <li>
                <a href="#" className="text-blue-500 hover:underline">
                  Academic Documents
                </a>
              </li>
              <li>
                <a href="#" className="text-blue-500 hover:underline">
                  Curriculum Vitae
                </a>
              </li>
              <li>
                <a href="#" className="text-blue-500 hover:underline">
                  Statement of Purpose/ Motivation Letter
                </a>
              </li>
              <li>
                <a href="#" className="text-blue-500 hover:underline">
                  Governmental Documents/Applications
                </a>
              </li>
            </ul>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md text-center">
            <img src="card1.png" alt="Career Counselling" className="mx-auto mb-4" />
            <h4 className="text-xl font-bold mb-2">Career Counselling</h4>
            <p className="mb-4">
              All our counselling sessions are free and our experienced
              counsellors help you with the university and course selection.
            </p>
            <a href="#" className="bg-[#eb4934] px-4 py-2 text-white font-bold rounded inline-block hover:bg-[#d43f2b]">
              Know More
            </a>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md text-center">
            <img src="card2.png" alt="Visa Guidance" className="mx-auto mb-4" />
            <h4 className="text-xl font-bold mb-2">Visa Guidance</h4>
            <p className="mb-4">
              Need to apply for a Visa? When should you apply for the student
              visa? How do you apply for the student visa?
            </p>
            <a href="#" className="bg-[#eb4934] px-4 py-2 text-white font-bold rounded inline-block hover:bg-[#d43f2b]">
              Know More
            </a>
          </div>
        </div>
      <TestPrep />
      <Destination />
      <BranchGridHome />
      <EducationalConsultancy />
      <FAQ />
      </div>
    </div>
  );
}

export default Home;
