import React from 'react'

function LifeAtUk() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">Life at the United Kingdom</h1>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Cultural Diversity</h2>
        <p className="text-lg text-gray-700">
          The UK is known for its rich cultural diversity and vibrant multicultural communities. Students will experience a mix of traditions and cultures, making it a dynamic and inclusive environment for international students.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Student Life</h2>
        <p className="text-lg text-gray-700">
          Student life in the UK is lively and varied, with numerous clubs, societies, and activities available. Universities often host events, social gatherings, and sports competitions, providing ample opportunities for students to engage and make new friends.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Accommodation</h2>
        <p className="text-lg text-gray-700">
          Students in the UK have several accommodation options, including university halls of residence, private rentals, and shared houses. It’s advisable to start searching for accommodation early and choose an option that fits your budget and preferences.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Healthcare</h2>
        <p className="text-lg text-gray-700">
          The UK provides excellent healthcare services through the National Health Service (NHS). International students are typically required to pay the Immigration Health Surcharge as part of their visa application to access NHS services.
        </p>
      </section>

      <section>
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Weather and Lifestyle</h2>
        <p className="text-lg text-gray-700">
          The UK has a temperate maritime climate with mild summers and cool winters. Students will enjoy a diverse range of outdoor activities, historical sites, and cultural experiences. The relaxed lifestyle and rich heritage make it an attractive place to study.
        </p>
      </section>
    </div>
  );
};

export default LifeAtUk;