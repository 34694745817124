import React from 'react'

function VisaAndProcessingCanada() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">Visa and Processing for Canada</h1>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Student Visa Requirements</h2>
        <p className="text-lg text-gray-700">
          To study in Canada, international students need a study permit. The requirements generally include proof of acceptance from a designated learning institution (DLI), proof of sufficient funds to cover tuition and living expenses, and a clean criminal record. A medical exam may also be required.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Application Process</h2>
        <ol className="list-decimal pl-5 text-lg text-gray-700">
          <li className="mb-2">Receive an acceptance letter from a designated learning institution (DLI).</li>
          <li className="mb-2">Prepare required documents including proof of funds, identity documents, and medical exam results if applicable.</li>
          <li className="mb-2">Submit your study permit application online or at a Canadian visa office.</li>
          <li className="mb-2">Attend an interview if required and wait for a decision on your application.</li>
        </ol>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Processing Time</h2>
        <p className="text-lg text-gray-700">
          The processing time for a Canadian study permit application can vary depending on the country of residence and the volume of applications. On average, it can take several weeks to a few months. It is advisable to apply well in advance of your intended start date.
        </p>
      </section>

      <section>
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">After Arrival</h2>
        <p className="text-lg text-gray-700">
          Upon arrival in Canada, make sure to carry all relevant documents with you, including your study permit, acceptance letter, and proof of funds. It is important to follow the conditions of your study permit and stay informed about any changes in immigration policies.
        </p>
      </section>
      <button className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold py-2 px-4 rounded">
        Learn More
      </button>
    </div>
  )
}

export default VisaAndProcessingCanada