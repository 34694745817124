import React from "react";
import { Outlet } from "react-router-dom";
import CanadaTabs from "./navCanada";

const StudyInCanada = () => {
    return (
        <div className="max-w-7xl mx-auto p-4">
            <h1 className="text-4xl font-bold mb-6 text-center">Study in Canada</h1>
            <CanadaTabs />
            <Outlet />
        </div>
    );
};

export default StudyInCanada;
