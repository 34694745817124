import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Nav from "./components/nav";
import Header from "./components/header";
import Footer from "./components/footer";
import PrimaryRoutes from "./routes/primaryRoutes";
import "./App.css";

function App() {
  return (
    <Router>
        <Header />
        <Nav />
        <PrimaryRoutes />
        <Footer />
    </Router>
  );
}

export default App;
