import React from 'react'

function LifeAtAmerica() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">Life in America as Student</h1>
      
      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Introduction</h2>
        <p className="text-gray-700 leading-relaxed">
          Studying in America offers an enriching experience for students from all over the world. 
          From world-class educational institutions to diverse cultural experiences, life as a student 
          in the U.S. is full of opportunities and challenges that help shape your future.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Academic Environment</h2>
        <p className="text-gray-700 leading-relaxed">
          The academic environment in American universities is known for its rigor and innovation. 
          You will have access to state-of-the-art facilities, experienced professors, and a wide range of 
          resources. Collaboration, critical thinking, and independent research are highly encouraged, allowing 
          students to develop skills that are highly valued in the global market.
        </p>
        <div className="bg-gray-100 p-4 rounded-lg mt-4">
          <h3 className="text-xl font-semibold mb-2">Fact:</h3>
          <p className="text-gray-600">
            The United States has more than 4,000 accredited higher education institutions, offering a wide range of programs and degrees.
            <a href="https://educationusa.state.gov/your-5-steps-us-study/research-your-options/undergraduate" target="_blank" rel="noopener noreferrer" className="text-blue-500 ml-1">
              (EducationUSA)
            </a>
          </p>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Campus Life</h2>
        <p className="text-gray-700 leading-relaxed">
          Campus life in America is vibrant and diverse. Most universities have numerous student organizations, 
          clubs, and events that cater to a wide range of interests. Whether you are into sports, arts, technology, 
          or social causes, you will find a community that shares your passion. This is a great way to make friends 
          and build a network that can support you during your studies and beyond.
        </p>
        <div className="bg-gray-100 p-4 rounded-lg mt-4">
          <h3 className="text-xl font-semibold mb-2">Fact:</h3>
          <p className="text-gray-600">
            Over 1 million international students were enrolled in U.S. institutions in the 2022/2023 academic year, contributing to the rich diversity on campus.
            <a href="https://opendoorsdata.org/data/international-students/academic-level/" target="_blank" rel="noopener noreferrer" className="text-blue-500 ml-1">
              (Open Doors Report)
            </a>
          </p>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Cultural Experience</h2>
        <p className="text-gray-700 leading-relaxed">
          Living in America as a student is a cultural immersion experience. You will be exposed to a mix of cultures, 
          languages, and traditions. This diversity offers a unique opportunity to learn and grow as an individual. 
          It also helps you gain a broader perspective on global issues and appreciate the richness of different cultures.
        </p>
        <div className="bg-gray-100 p-4 rounded-lg mt-4">
          <h3 className="text-xl font-semibold mb-2">Fact:</h3>
          <p className="text-gray-600">
            The U.S. is home to people from over 200 countries, making it one of the most culturally diverse nations in the world.
            <a href="https://www.census.gov/library/stories/2020/10/celebrating-diversity-of-americas-population.html" target="_blank" rel="noopener noreferrer" className="text-blue-500 ml-1">
              (U.S. Census Bureau)
            </a>
          </p>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Challenges and Opportunities</h2>
        <p className="text-gray-700 leading-relaxed">
          While studying in America is an exciting adventure, it comes with its own set of challenges. Adapting to a 
          new education system, dealing with homesickness, and managing finances can be tough. However, these challenges 
          also offer opportunities for personal growth. With time, you will develop resilience, independence, and the ability 
          to thrive in different environments.
        </p>
        <div className="bg-gray-100 p-4 rounded-lg mt-4">
          <h3 className="text-xl font-semibold mb-2">Fact:</h3>
          <p className="text-gray-600">
            62% of international students in the U.S. feel that studying abroad has helped them develop important life skills and gain a broader perspective.
            <a href="https://www.iie.org/Research-and-Insights/Open-Doors/Data/International-Students" target="_blank" rel="noopener noreferrer" className="text-blue-500 ml-1">
              (Institute of International Education)
            </a>
          </p>
        </div>
      </section>

      <section>
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Conclusion</h2>
        <p className="text-gray-700 leading-relaxed">
          Life in America as a student is a transformative experience. It provides not only a high-quality education but 
          also the chance to grow personally and professionally. The memories, skills, and relationships you build during 
          your time as a student in America will stay with you for a lifetime, shaping the person you become.
        </p>
        <div className="bg-gray-100 p-4 rounded-lg mt-4">
          <h3 className="text-xl font-semibold mb-2">Fact:</h3>
          <p className="text-gray-600">
            85% of international students in the U.S. report that they would recommend studying in the U.S. to others.
            <a href="https://www.iie.org/Research-and-Insights/Open-Doors/Data/International-Students" target="_blank" rel="noopener noreferrer" className="text-blue-500 ml-1">
              (Institute of International Education)
            </a>
          </p>
        </div>
      </section>
    </div>
  );
};

export default LifeAtAmerica