import React from 'react'

function LifeAtCanada() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">Life at Canada</h1>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Cultural Diversity</h2>
        <p className="text-lg text-gray-700">
          Canada is known for its multicultural society, welcoming people from various backgrounds. This diversity is reflected in its cultural festivals, cuisine, and community events, offering international students a rich and inclusive experience.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Student Life</h2>
        <p className="text-lg text-gray-700">
          Student life in Canada is vibrant, with numerous clubs, organizations, and activities to engage in. Universities offer a range of recreational facilities, social events, and support services to help students balance their academic and personal lives.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Accommodation</h2>
        <p className="text-lg text-gray-700">
          Students have various accommodation options in Canada, including on-campus residences, private rentals, and shared apartments. It’s important to choose an option that suits your budget and preferences, and to arrange accommodation well in advance.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Healthcare</h2>
        <p className="text-lg text-gray-700">
          Canada provides excellent healthcare services to its residents. International students are typically required to obtain health insurance, either through their university or a private provider, to cover medical expenses during their stay.
        </p>
      </section>

      <section>
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Weather and Lifestyle</h2>
        <p className="text-lg text-gray-700">
          Canada's weather can vary widely depending on the region, from cold winters in the northern areas to milder climates in the southern regions. Embrace the diverse outdoor activities and seasonal events that each region offers.
        </p>
      </section>
    </div>
  )
}

export default LifeAtCanada