import React from 'react';
import Destinations from '../../../data/destinationdata';

const OverviewEurope = () => {
  // Find the destination object for Europe
  const europe = Destinations.find(dest => dest.name === "Europe");

  // Sort universities by rank
  const sortedUniversities = europe.universities.sort((a, b) => {
    const rankA = parseInt(a.rank.split(' ')[0].replace(/\D/g, ''), 10);
    const rankB = parseInt(b.rank.split(' ')[0].replace(/\D/g, ''), 10);
    return rankA - rankB;
  });

  // Get the top 5 universities
  const topUniversities = sortedUniversities.slice(0, 5);

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">Overview</h1>
      
      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Why Study in Europe?</h2>
        <p className="text-lg text-gray-700">
          Europe is home to some of the world's oldest and most prestigious universities. The continent offers a rich cultural heritage, diverse educational opportunities, and a variety of study programs in multiple languages. Studying in Europe provides an exceptional academic experience and a chance to explore a multitude of cultures.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Top Universities</h2>
        <ul className="list-disc pl-5 text-lg text-gray-700 font-semibold">
          {topUniversities.map((university, index) => (
            <li key={index}>{university.name} ({university.rank})</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Admission Requirements</h2>
        <p className="text-lg text-gray-700">
          Admission requirements in Europe can vary by country and institution but typically include academic transcripts, proof of language proficiency (e.g., TOEFL, IELTS, or equivalent), letters of recommendation, and a personal statement. Some programs may also require standardized test scores.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Cost of Living</h2>
        <p className="text-lg text-gray-700">
          The cost of living in Europe varies widely. Western European countries like Switzerland and Germany tend to be more expensive, while Eastern European countries offer more affordable living options. Students should budget for tuition, housing, food, transportation, and other expenses based on their location.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Scholarships and Financial Aid</h2>
        <p className="text-lg text-gray-700">
          Europe offers a range of scholarships and financial aid options for international students, including Erasmus+ scholarships, national government-funded programs, and university-specific awards. Research and apply for financial aid opportunities early to maximize your chances.
        </p>
      </section>

      <section>
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Post-Study Opportunities</h2>
        <p className="text-lg text-gray-700">
          Many European countries offer post-study work visas allowing graduates to remain in the country to seek employment. These visas vary by country, with some offering longer durations for STEM graduates or those with job offers in specific fields.
        </p>
      </section>

      <button className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold py-2 px-4 rounded">
        Start Your Application
      </button>
    </div>
  );
}

export default OverviewEurope;
