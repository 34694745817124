import React from 'react';
import Destinations from '../../../data/destinationdata';

const OverviewCanada = () => {
    // Find the destination object for Canada
    const canada = Destinations.find(dest => dest.name === "Canada");

    // Sort universities by rank
    const sortedUniversities = canada.universities.sort((a, b) => {
        const rankA = parseInt(a.rank.split(' ')[0].replace(/\D/g, ''), 10);
        const rankB = parseInt(b.rank.split(' ')[0].replace(/\D/g, ''), 10);
        return rankA - rankB;
    });

    // Get the top 5 universities
    const topUniversities = sortedUniversities.slice(0, 5);

    return (
        <div className="max-w-7xl mx-auto p-6">
            <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">Overview</h1>

            <section className="mb-8">
                <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Why Study in Canada?</h2>
                <p className="text-lg text-gray-700">
                    Canada is renowned for its high-quality education system, diverse cultural landscape, and welcoming environment. With numerous world-class universities and a strong emphasis on research and innovation, Canada provides students with exceptional opportunities for academic and personal growth.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Top Universities</h2>
                <ul className="list-disc pl-5 text-lg text-gray-700 font-semibold">
                    {topUniversities.map((university, index) => (
                        <li key={index}>{university.name} ({university.rank})</li>
                    ))}
                </ul>
            </section>

            <section className="mb-8">
                <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Admission Requirements</h2>
                <p className="text-lg text-gray-700">
                    To gain admission to Canadian universities, you typically need to provide academic transcripts, proof of English language proficiency (e.g., IELTS or TOEFL), letters of recommendation, and a personal statement. Some programs may also require standardized test scores.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Cost of Living</h2>
                <p className="text-lg text-gray-700">
                    The cost of living in Canada varies depending on the city. Major cities like Toronto and Vancouver are more expensive, while smaller cities and towns offer more affordable living options. Students should plan for tuition, accommodation, food, transportation, and other personal expenses.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Scholarships and Financial Aid</h2>
                <p className="text-lg text-gray-700">
                    Canada offers various scholarships and financial aid opportunities for international students. These include government-funded scholarships, university-specific grants, and private scholarships. Research and apply for these opportunities early to maximize your chances of receiving financial support.
                </p>
            </section>

            <section>
                <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Post-Study Opportunities</h2>
                <p className="text-lg text-gray-700">
                    International graduates in Canada can apply for the Post-Graduation Work Permit (PGWP), which allows them to work in Canada for a period equal to their study program length, up to three years. This provides valuable work experience and can be a pathway to permanent residency.
                </p>
            </section>

            <button className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold py-2 px-4 rounded">
                Start Your Application
            </button>
        </div>
    );
}

export default OverviewCanada;
