import React from "react";

const AboutUs = () => {
  return (
    <div className="bg-gray-50 min-h-screen py-12">
      <div className="container mx-auto px-6 lg:px-12">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
          About Us
        </h1>
        <div className="flex flex-col lg:flex-row items-center lg:items-start space-y-8 lg:space-y-0 lg:space-x-12">
          <div className="lg:w-1/2">
            <img
              src="logo.png"
              alt="Educational Consultancy"
              className="rounded-lg shadow-lg w-full"
            />
          </div>
          <div className="lg:w-1/2">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">
              Who We Are
            </h2>
            <p className="text-gray-600 text-lg leading-relaxed mb-6">
              We are a leading educational consultancy firm, dedicated to
              guiding students towards academic success. With years of
              experience in the education sector, we provide personalized
              support, helping students find the best institutions and programs
              globally.
            </p>

            <h2 className="text-2xl font-semibold text-gray-700 mb-4">
              Our Mission
            </h2>
            <p className="text-gray-600 text-lg leading-relaxed mb-6">
              Our mission is to empower students by providing expert advice and
              comprehensive solutions for their academic journey. We believe
              that every student deserves access to the best education
              opportunities and the resources to thrive.
            </p>

            <h2 className="text-2xl font-semibold text-gray-700 mb-4">
              Why Choose Us
            </h2>
            <ul className="list-disc pl-6 text-gray-600 text-lg leading-relaxed">
              <li>Expert Guidance from Experienced Advisors</li>
              <li>Comprehensive Support for International Students</li>
              <li>Partnerships with Top Educational Institutions</li>
              <li>Tailored Solutions to Meet Individual Needs</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
