import React from "react";
import { Outlet } from "react-router-dom";
import EuropeTabs from "./navEurope";

const StudyInEurope = () => {
    return (
        <div className="max-w-7xl mx-auto p-4">
            <h1 className="text-4xl font-bold mb-6 text-center">Study in Europe</h1>
            <EuropeTabs />
            <Outlet/>
        </div>
    );
};

export default StudyInEurope;
