import React, { useState, useEffect, useRef } from "react";
import {Link} from "react-router-dom";
import Example from "./images/1.png";
import Example1 from "./images/2.png";
import Example2 from "./images/3.png";

function ControlledCarousel() {
  const images = [
    { src: Example, alt: "First slide" },
    { src: Example1, alt: "Second slide" },
    { src: Example2, alt: "Third slide" },
  ];

  const [index, setIndex] = useState(0);
  const [dragStart, setDragStart] = useState(null);
  const [dragging, setDragging] = useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {
    const handleNext = () => {
      setIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    const interval = setInterval(handleNext, 5000); // Automatically change slide every 5 seconds
    return () => clearInterval(interval); // Clear the interval on component unmount
  }, [images.length]);

  const handleMouseDown = (e) => {
    setDragStart(e.clientX);
    setDragging(true);
  };

  const handleMouseMove = (e) => {
    if (dragging && carouselRef.current) {
      const dragDistance = e.clientX - dragStart;
      const newIndex = Math.round(index - (dragDistance / carouselRef.current.clientWidth));
      setIndex(newIndex < 0 ? 0 : newIndex >= images.length ? images.length - 1 : newIndex);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
    setDragStart(null);
  };

  return (
    <div
      className="relative w-full h-[80vh] overflow-hidden"
      ref={carouselRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp} // Handle case where mouse leaves the area
    >
      <div
        className="relative w-full h-full flex transition-transform duration-500 ease-in-out"
        style={{ transform: `translateX(-${index * 100}%)` }}
      >
        {images.map((image, i) => (
          <div key={i} className="w-full h-full flex-shrink-0 relative">
            <img className="w-full h-full object-cover" src={image.src} alt={image.alt} />
            {i === 0 && (
              <div className="absolute bottom-6 right-6 bg-white p-6 rounded-lg shadow-lg max-w-xs z-10">
                <h3 className="text-xl font-bold mb-2">Study Europe!</h3>
                <p className="text-sm mb-4">Study Europe at only 4 lacs. Contact us for more information about this offer!</p>
                <Link 
                to="/make-an-enquiry"
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
                  Apply Now
                </Link>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {images.map((_, i) => (
          <span
            key={i}
            className={`w-3 h-3 rounded-full cursor-pointer ${index === i ? "bg-black" : "bg-gray-300"}`}
            onClick={() => setIndex(i)}
          ></span>
        ))}
      </div>
    </div>
  );
}

export default ControlledCarousel;
