export const FAQdata = [
    {
      "id": 1,
      "question": "What services does AAEC Global offer?",
      "answer": "AAEC Global provides educational consultancy, including course selection, university applications, visa assistance, and test preparation."
    },
    {
      "id": 2,
      "question": "Which countries can I study in through AAEC Global?",
      "answer": "You can study in Australia, Canada, the USA, the UK, New Zealand, and various European countries."
    },
    {
      "id": 3,
      "question": "What test preparation classes are available?",
      "answer": "We offer preparation classes for TOEFL, PTE-A, IELTS, GRE, and GMAT."
    },
    {
      "id": 4,
      "question": "How can AAEC Global assist with visa applications?",
      "answer": "We provide guidance on visa requirements, application procedures, and compliance with regulations."
    },
    {
      "id": 5,
      "question": "What is the process for receiving consultancy services?",
      "answer": "The process includes an initial consultation, personalized advice, application assistance, and ongoing support."
    },
    {
      "id": 6,
      "question": "How do I schedule a consultation with AAEC Global?",
      "answer": "You can schedule a consultation via our website’s contact form, by phone, or through email."
    },
    {
      "id": 7,
      "question": "What are the eligibility requirements for studying abroad?",
      "answer": "Eligibility requirements vary by country and institution but generally include academic qualifications, language proficiency, and financial stability."
    },
    {
      "id": 8,
      "question": "How long does the application process take?",
      "answer": "The application process can take several weeks to months, depending on the country, institution, and individual requirements."
    },
    {
      "id": 9,
      "question": "Can AAEC Global help with scholarship applications?",
      "answer": "Yes, we provide guidance on finding and applying for scholarships available for studying abroad."
    },
    {
      "id": 10,
      "question": "What should I include in my study abroad application?",
      "answer": "Your application typically includes academic transcripts, recommendation letters, a statement of purpose, and proof of language proficiency."
    },
    {
      "id": 11,
      "question": "How do I prepare for standardized tests like TOEFL or IELTS?",
      "answer": "We offer preparation classes and resources to help you achieve the best possible scores on these tests."
    },
    {
      "id": 12,
      "question": "What is the cost of studying abroad?",
      "answer": "Costs vary widely depending on the country, institution, and program. We provide information on tuition fees and living expenses."
    },
    {
      "id": 13,
      "question": "How can I improve my chances of getting accepted into a top university?",
      "answer": "Focus on maintaining strong academic performance, preparing a compelling application, and achieving high scores on standardized tests."
    },
    {
      "id": 14,
      "question": "What types of visas are required for studying abroad?",
      "answer": "Student visas are generally required, and specific types depend on the destination country."
    },
    {
      "id": 15,
      "question": "Can AAEC Global assist with finding accommodation abroad?",
      "answer": "We can provide information and resources for finding accommodation, though we do not directly arrange housing."
    },
    {
      "id": 16,
      "question": "What should I do if I encounter problems during my studies abroad?",
      "answer": "Contact our support team for guidance and assistance with any issues that arise during your studies."
    },
    {
      "id": 17,
      "question": "How can I stay connected with AAEC Global while studying abroad?",
      "answer": "You can stay in touch via email, phone, or through our website’s support channels."
    },
    {
      "id": 18,
      "question": "Are there any special programs for international students?",
      "answer": "Many institutions offer programs and services specifically designed for international students, including orientation, support groups, and cultural activities."
    },
    {
      "id": 19,
      "question": "How do I handle cultural differences and adjust to studying in a new country?",
      "answer": "We offer advice and resources on cultural adaptation and managing the transition to life in a new country."
    },
    {
      "id": 20,
      "question": "What are the career prospects after studying abroad?",
      "answer": "Studying abroad can enhance your career prospects by providing international experience, broadening your network, and improving language skills."
    }
  ]
  