import React from 'react';

const GMATInfo = () => {
    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
            <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">About the GMAT Exam</h1>
            <p className="text-gray-700 mb-4">
                The Graduate Management Admission Test (GMAT) is a standardized test used for admissions to graduate business programs. It assesses analytical, writing, quantitative, verbal, and reading skills.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Test Format</h2>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Analytical Writing: 1 essay, 30 minutes</li>
                <li>Integrated Reasoning: 12 questions, 30 minutes</li>
                <li>Quantitative: 31 questions, 62 minutes</li>
                <li>Verbal: 36 questions, 65 minutes</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Scoring System</h2>
            <p className="text-gray-700 mb-4">
                Total scores range from 200 to 800, based on the quantitative and verbal sections.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Preparing for the GMAT</h2>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Review GMAT-specific study materials.</li>
                <li>Practice with real GMAT questions.</li>
                <li>Take full-length practice tests.</li>
            </ul>
        </div>
    );
};

export default GMATInfo;
