import React from 'react'

function VisaAndProcessingAmerica() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">
        Visa and Processing for International Students in the USA
      </h1>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Types of Student Visas</h2>
        <p className="text-lg text-gray-700">
          The United States offers several types of visas for international students, depending on the nature of your study program. Here are the main categories:
        </p>
        <ul className="list-disc pl-5 text-lg text-gray-700">
          <li className="mb-2">
            <strong>F-1 Visa:</strong> The most common visa for international students, the F-1 Visa is issued to students attending an academic program or English language program. F-1 students must maintain a full course load.
          </li>
          <li className="mb-2">
            <strong>M-1 Visa:</strong> This visa is for students attending vocational or technical schools. M-1 students are not allowed to work during their studies.
          </li>
          <li className="mb-2">
            <strong>J-1 Visa:</strong> Issued to students participating in an exchange program, including students on short-term study programs, internships, or research. J-1 students may be eligible for practical training related to their field of study.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Visa Application Process</h2>
        <p className="text-lg text-gray-700">
          Applying for a student visa involves several steps, and it's crucial to start the process well in advance of your planned departure to the United States. Here is an overview of the typical steps:
        </p>
        <ol className="list-decimal pl-5 text-lg text-gray-700">
          <li className="mb-2">
            <strong>Receive Your I-20 or DS-2019:</strong> After being accepted by a SEVP-approved school, you will receive an I-20 form for F-1 or M-1 visas, or a DS-2019 form for J-1 visas.
          </li>
          <li className="mb-2">
            <strong>Pay the SEVIS Fee:</strong> Before applying for your visa, you must pay the SEVIS (Student and Exchange Visitor Information System) fee online.
          </li>
          <li className="mb-2">
            <strong>Complete the DS-160 Form:</strong> Fill out the DS-160 nonimmigrant visa application form online. After submission, print the confirmation page for your records.
          </li>
          <li className="mb-2">
            <strong>Schedule a Visa Interview:</strong> Schedule an appointment for your visa interview at the nearest U.S. Embassy or Consulate. Visa wait times can vary, so plan accordingly.
          </li>
          <li className="mb-2">
            <strong>Attend the Visa Interview:</strong> During the interview, you will be asked about your study plans, financial support, and intent to return to your home country after your studies.
          </li>
          <li className="mb-2">
            <strong>Receive Your Visa:</strong> If your visa application is approved, your passport with the visa will be returned to you. Processing times can vary depending on the consulate.
          </li>
        </ol>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Documents Required for Visa Application</h2>
        <p className="text-lg text-gray-700">
          When applying for a student visa, you will need to provide several documents. Be sure to have the following ready:
        </p>
        <ul className="list-disc pl-5 text-lg text-gray-700">
          <li className="mb-2">A valid passport with at least six months of validity beyond your intended stay in the U.S.</li>
          <li className="mb-2">Form I-20 or DS-2019 issued by your school.</li>
          <li className="mb-2">Receipt of the SEVIS fee payment.</li>
          <li className="mb-2">DS-160 confirmation page.</li>
          <li className="mb-2">Visa application fee payment receipt.</li>
          <li className="mb-2">Passport-sized photo that meets U.S. visa requirements.</li>
          <li className="mb-2">Proof of financial ability to cover your tuition and living expenses in the U.S.</li>
          <li className="mb-2">Academic transcripts and standardized test scores (if required).</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Visa Interview Tips</h2>
        <p className="text-lg text-gray-700">
          The visa interview is a critical part of the application process. Here are some tips to help you prepare:
        </p>
        <ul className="list-disc pl-5 text-lg text-gray-700">
          <li className="mb-2">Be honest and confident in your answers.</li>
          <li className="mb-2">Clearly articulate your study plans and career goals.</li>
          <li className="mb-2">Have a solid understanding of your chosen program and how it fits into your future plans.</li>
          <li className="mb-2">Bring all required documents and be ready to present them if asked.</li>
          <li className="mb-2">Dress professionally and arrive early for your interview.</li>
        </ul>
      </section>

      <section>
        <p className="text-lg text-gray-700">
          Securing a U.S. student visa is an important step in your journey to study in the United States. By following the process carefully and preparing thoroughly, you can increase your chances of a successful application.
        </p>
      </section>
      <button className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold py-2 px-4 rounded">
        Learn More
      </button>
    </div>
  );
};

export default VisaAndProcessingAmerica