
import React from 'react';

const IELTSinfo = () => {
    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
            <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">IELTS Preparation Classes</h1>
            <p className="text-gray-700 mb-4">
                IELTS preparation classes are designed to help students develop the skills and strategies needed to excel in the IELTS exam. These classes cover all four sections of the test: Listening, Reading, Writing, and Speaking.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Types of IELTS Classes</h2>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li><strong>Group Classes:</strong> Interactive sessions with a teacher and multiple students, promoting collaborative learning.</li>
                <li><strong>Private Tutoring:</strong> One-on-one sessions tailored to the individual needs of the student, allowing for personalized feedback.</li>
                <li><strong>Online Classes:</strong> Flexible options that can be taken from anywhere, ideal for busy schedules.</li>
                <li><strong>Intensive Courses:</strong> Short, focused courses designed for those needing quick preparation.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Benefits of Taking IELTS Classes</h2>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Expert guidance from experienced instructors.</li>
                <li>Access to practice materials and mock tests.</li>
                <li>Improved understanding of the test format and requirements.</li>
                <li>Targeted skill development in areas of weakness.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">What to Expect in IELTS Classes</h2>
            <p className="text-gray-700 mb-4">
                During IELTS classes, you can expect:
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Practice exercises for each test component.</li>
                <li>Feedback on writing tasks and speaking performance.</li>
                <li>Strategies for time management and answering techniques.</li>
                <li>Simulated test environments to build confidence.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Finding the Right IELTS Class</h2>
            <p className="text-gray-700 mb-4">
                When choosing an IELTS class, consider:
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>The class size and student-to-teacher ratio.</li>
                <li>Reviews and testimonials from past students.</li>
                <li>The experience and qualifications of the instructor.</li>
                <li>Flexibility in scheduling and location.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Useful Resources</h2>
            <p className="text-gray-700 mb-4">
                Here are some resources to help you find IELTS classes:
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li><a href="https://www.ielts.org/" className="text-blue-500 hover:underline">IELTS Official Website</a></li>
                <li><a href="https://www.britishcouncil.org/exam/ielts/prepare" className="text-blue-500 hover:underline">British Council IELTS Preparation</a></li>
                <li><a href="https://www.ieltsliz.com/" className="text-blue-500 hover:underline">IELTS Liz</a></li>
            </ul>

        </div>
    );
};

export default IELTSinfo;
