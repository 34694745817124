import React from 'react';

const PTEInfo = () => {
    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
            <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">About the PTE Exam</h1>
            <p className="text-gray-700 mb-4">
                The Pearson Test of English (PTE) is an English language test that assesses the speaking, listening, reading, and writing skills of non-native English speakers.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Test Format</h2>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Speaking and Writing: 77-93 minutes</li>
                <li>Reading: 32-41 minutes</li>
                <li>Listening: 45-57 minutes</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Scoring System</h2>
            <p className="text-gray-700 mb-4">
                Scores range from 10 to 90, reflecting the test taker's English language proficiency.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Preparing for the PTE</h2>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Familiarize yourself with the test format and types of questions.</li>
                <li>Practice speaking and writing under timed conditions.</li>
                <li>Use official PTE preparation materials.</li>
            </ul>
        </div>
    );
};

export default PTEInfo;
