import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-gray-100 p-4 flex justify-between items-center">
      <nav className="flex items-center w-full justify-end">
        <ul className="flex items-center gap-4">
          <li className="text-lg font-bold text-gray-900 ml-4">
            An Award Winning Educational Consultancy
          </li>
          <li>
            <Link
              to="/about-us"
              className="bg-transparent rounded text-orange-500 hover:bg-orange-500 hover:text-white border-none text-base py-1 px-2 transition-colors duration-300"
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="mailto:info@aaecglobal.com"
              className="bg-transparent rounded text-blue-900 hover:bg-blue-900 hover:text-white border-none text-base py-1 px-2 transition-colors duration-300"
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
