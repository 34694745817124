import React from 'react'

function VisaAndProcessingEurope() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">Visa and Processing for Europe</h1>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Student Visa Requirements</h2>
        <p className="text-lg text-gray-700">
          Student visa requirements for Europe can vary significantly depending on the country you plan to study in. Generally, you will need the following documents:
        </p>
        <ul className="list-disc pl-5 text-lg text-gray-700">
          <li className="mb-2">Acceptance letter from a recognized institution</li>
          <li className="mb-2">Proof of financial means to cover tuition and living expenses</li>
          <li className="mb-2">Health insurance coverage</li>
          <li className="mb-2">Proof of accommodation</li>
          <li className="mb-2">Passport-sized photographs</li>
          <li className="mb-2">Completed visa application form</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Country-Specific Requirements</h2>
        <p className="text-lg text-gray-700">
          Requirements can differ by country. Here are some specific requirements for a few European countries:
        </p>
        <div className="mb-4">
          <h3 className="text-lg md:text-xl lg:text-2xl font-semibold mb-2">Germany</h3>
          <p className="text-lg text-gray-700">
            To study in Germany, you need to provide proof of financial means, usually through a blocked account with sufficient funds. You also need to show proof of health insurance coverage and a valid passport. You may be required to attend an interview at the German consulate.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg md:text-xl lg:text-2xl font-semibold mb-2">France</h3>
          <p className="text-lg text-gray-700">
            For France, you need an acceptance letter, proof of financial means, health insurance, and proof of accommodation. You will also need to submit a visa application through the Campus France website and may be required to attend an interview.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg md:text-xl lg:text-2xl font-semibold mb-2">United Kingdom</h3>
          <p className="text-lg text-gray-700">
            In the UK, you will need to provide a Confirmation of Acceptance for Studies (CAS), proof of financial means, and proof of English language proficiency. You may also need to provide biometric information and attend a visa application center for an interview.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg md:text-xl lg:text-2xl font-semibold mb-2">Netherlands</h3>
          <p className="text-lg text-gray-700">
            To study in the Netherlands, you need proof of admission, proof of financial means, and health insurance. You may also need to register with the local municipality upon arrival and apply for a residence permit.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg md:text-xl lg:text-2xl font-semibold mb-2">Spain</h3>
          <p className="text-lg text-gray-700">
            For Spain, you will need proof of enrollment, financial means, and health insurance. You must apply for the student visa through the Spanish consulate and may be asked to provide additional documentation, such as a criminal background check.
          </p>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Application Process</h2>
        <ol className="list-decimal pl-5 text-lg text-gray-700">
          <li className="mb-2">Obtain an acceptance letter from a European university.</li>
          <li className="mb-2">Prepare necessary documents including proof of financial means, health insurance, and accommodation details.</li>
          <li className="mb-2">Submit your visa application through the relevant embassy or consulate.</li>
          <li className="mb-2">Attend a biometric appointment and interview if required.</li>
        </ol>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Processing Time</h2>
        <p className="text-lg text-gray-700">
          Processing times for European student visas can vary by country and time of year, typically ranging from a few weeks to several months. It is essential to apply as early as possible to ensure you have enough time for any additional requirements or interviews.
        </p>
      </section>

      <section>
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">After Arrival</h2>
        <p className="text-lg text-gray-700">
          Upon arrival in Europe, you may need to register with local authorities, obtain a residence permit if required, and comply with visa conditions. Familiarize yourself with local regulations and requirements to ensure a smooth transition.
        </p>
      </section>

      <button className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold py-2 px-4 rounded">
        Learn More
      </button>
    </div>
  );
};

export default VisaAndProcessingEurope;