import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center  bg-[#01132b] text-white p-10">
      <div className="text-center">
        <h1 className="text-7xl md:text-9xl font-extrabold mb-4">404</h1>
        <p className="text-lg md:text-2xl font-light mb-6">
          Sorry, the page you're looking for cannot be found.
        </p>
        <Link
          to="/"
          className="inline-block bg-white text-indigo-600 font-semibold px-6 py-3 md:px-8 md:py-4 rounded-full shadow-lg hover:bg-gray-100 transition duration-300 ease-in-out"
        >
          Go Back to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
