import React from "react";
import { Outlet } from "react-router-dom";
import AmericaTabs from "./navAmerica";

const StudyInAmerica = () => {
    return (
        <div className="max-w-7xl mx-auto p-4">
            <h1 className="text-4xl font-bold mb-6 text-center">Study in America</h1>
            <AmericaTabs />
            <Outlet/>
        </div>
    );
};

export default StudyInAmerica;
