import React from 'react';
import { Link } from 'react-router-dom';

const EducationalConsultancy = () => {
  return (
    <div className="bg-gray-100 p-8">
      <h1 className="text-3xl font-bold mb-4">
        Best Educational Consultancy in Kathmandu, Nepal to Study Abroad
      </h1>
      <p className="text-lg mb-6">
        The world is evolving every single second with so many newer developments taking place every single second. Newer opportunities are paving the way in varying industries which is why the sectors are thriving. ‘Good education’ is the foundation of everything and for that, there are ample opportunities across the world. A vast population garners the dream of getting a quality education for which there’s an increasing desire to study abroad.
      </p>
      <p className="text-lg mb-6">
        AAEC Global is paving the path for such aspirants. We are the best educational consultants in Nepal and aim to offer significant benefits to the students and aspirants who are willing to study abroad.
      </p>
      
      <h2 className="text-2xl font-semibold mb-4">Why Study Abroad?</h2>
      <p className="text-lg mb-6">
        AAEC Global is one of the leading providers of educational consultancy services in Nepal for aspirants who are willing to study abroad. Our educational consultants in Nepal have expertise in offering counseling to aspirants. USA, UK, Australia, Canada, New Zealand, Europe are some of the most preferable destinations for pursuing higher studies. Students from all across the world have a desire to study in developed countries as they have better academic structure, excellent career growth, and better living standards.
      </p>
      
      <h3 className="text-xl font-semibold mb-4">We extend our International Education Consultancy Nepal for:</h3>
      <ul className="list-decimal list-inside mb-6">
        <li>Australia</li>
        <li>Canada</li>
        <li>USA</li>
        <li>UK</li>
        <li>New Zealand</li>
        <li>Europe</li>
      </ul>
      
      <p className="text-lg mb-6">
        With developed countries having ample career opportunities as compared with the developing countries, students can work there part-time. And after completing their degree, they can also get several career opportunities staying in the same country. The majority of universities and colleges are recognized globally and valued across which is also an advantage that students get.
      </p>
      
      <h3 className="text-xl font-semibold mb-4">Our Overseas Education Consultants in Nepal guide the aspirants in:</h3>
      <ul className="list-disc list-inside mb-6">
        <li>Choosing the right academic course</li>
        <li>Information about varying VISA compliances, rules, and regulations</li>
        <li>Thorough guidance in all immigration procedures</li>
        <li>Dedicated counseling to every aspirant</li>
        <li>List of universities and colleges where they can study</li>
      </ul>
      
      <h3 className="text-xl font-semibold mb-4">Test Preparation Classes</h3>
      <p className="text-lg mb-6">
        We have the best overseas education consultants in Nepal who can offer classes and information on the following:
      </p>
      <ul className="list-none mb-6">
        <li className='mb-2'>
          <strong>TOEFL:</strong> Test of English as a Foreign Language or TOEFL, is a standardized test of English language proficiency for non-native English language speakers wishing to enroll in U.S. universities.
        </li>
        <li className='mb-2'>
          <strong>PTE-A (Personality Test of English Academic):</strong> This is the leading computer-based English test for immigration and studying abroad. The PTE-A assesses listening, reading, speaking, and writing in a single three-hour test session online.
        </li>
        <li className='mb-2'>
          <strong>IELTS (International English Language Testing System):</strong> This is the most popular high-stakes English language proficiency test conducted for the study, work, and immigration. There are two versions of IELTS: The Academic Version and The General Training Version.
        </li>
        <li className='mb-2'>
          <strong>GRE:</strong> GRE stands for Graduate Record Examinations. We have some of the best Nepal International education agents, who can guide you in these widely accepted admission tests for graduate and business school programs. This test is helpful for admissions decisions for MBA and doctoral programs.
        </li>
        <li className='mb-2'>
          <strong>GMAT:</strong> This stands for Graduate Management Admission Test. We ensure students get the best possible preparation by offering the best educational consultancy in Nepal. This test is required to seek admission in one of the world’s leading Business Schools.
        </li>
      </ul>
      
      <p className="text-lg mb-4">
        We are the best consultancy in Nepal and are committed to providing world-class career counseling and assistance to build a successful career overseas. Call now for a free counseling session to take the first step towards a successful future!
      </p>
      <Link to="/about-us">
        <button className="bg-[#eb4934] text-white px-4 py-2 rounded hover:bg-[#d43b2e]">
          Know More
        </button>
      </Link>
    </div>
  );
};

export default EducationalConsultancy;
