import React from 'react';
import Destinations from '../../../data/destinationdata';

const OverviewAustralia = () => {
  // Find the destination object for Australia
  const australia = Destinations.find(dest => dest.name === "Australia");

  // Sort universities by rank
  const sortedUniversities = australia.universities.sort((a, b) => {
    const rankA = parseInt(a.rank.split(' ')[0].replace(/\D/g, ''), 10);
    const rankB = parseInt(b.rank.split(' ')[0].replace(/\D/g, ''), 10);
    return rankA - rankB;
  });

  // Get the top 5 universities
  const topUniversities = sortedUniversities.slice(0, 5);

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">Overview</h1>
      
      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Why Study in Australia?</h2>
        <p className="text-lg text-gray-700">
          Australia is a popular destination for international students, known for its high-quality education system, multicultural society, and vibrant cities. The country offers a wide range of programs across various disciplines, with many universities ranked among the best in the world.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Top Universities</h2>
        <ul className="list-disc pl-5 text-lg text-gray-700 font-semibold">
          {topUniversities.map((university, index) => (
            <li key={index}>{university.name} ({university.rank})</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Admission Requirements</h2>
        <p className="text-lg text-gray-700">
          Admission requirements for Australian universities generally include academic transcripts, proof of English language proficiency (e.g., IELTS or TOEFL), letters of recommendation, and a personal statement. Some programs may require standardized test scores or interviews.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Cost of Living</h2>
        <p className="text-lg text-gray-700">
          The cost of living in Australia can vary depending on the city or town. Major cities like Sydney, Melbourne, and Brisbane are more expensive, while regional areas and smaller towns offer more affordable living options. Students should budget for tuition, accommodation, food, transportation, and other personal expenses.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Scholarships and Financial Aid</h2>
        <p className="text-lg text-gray-700">
          Australian universities and the government offer various scholarships and financial aid options for international students. Some popular scholarships include the Australia Awards, Research Training Program (RTP) Scholarships, and university-specific grants. Students are encouraged to explore these options early in the application process.
        </p>
      </section>

      <section>
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4">Post-Study Opportunities</h2>
        <p className="text-lg text-gray-700">
          After completing their studies, international students in Australia can apply for the Temporary Graduate Visa (Subclass 485), which allows them to work in Australia for a period of 18 months to 4 years, depending on their qualifications. This visa provides a pathway to gain valuable work experience in Australia.
        </p>
      </section>

      <button className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold py-2 px-4 rounded">
        Start Your Application
      </button>
    </div>
  );
}

export default OverviewAustralia;
