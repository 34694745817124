import React from "react";
import { Outlet } from "react-router-dom";
import UKTabs from "./navUK";

const StudyInUK = () => {
    return (
        <div className="max-w-7xl mx-auto p-4">
            <h1 className="text-4xl font-bold mb-6 text-center">Study in the United Kingdom</h1>
            <UKTabs />
            <Outlet />
        </div>
    );
};

export default StudyInUK;
