import React, { useState } from 'react';
import  destinations  from './../data/destinationdata'; // Adjust the path if necessary

const WorkWithUs = () => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [otherCountry, setOtherCountry] = useState('');

  // Extract country names from destinations
  const countryNames = destinations.map(dest => dest.name);

  const handleCountryChange = (event) => {
    const value = event.target.value;
    setSelectedCountry(value);

    // Clear manual input if a specific country is selected
    if (value !== 'Other') {
      setOtherCountry('');
    }
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold text-center text-[#eb4934] mb-4">New Partner Registration</h2>
      <p className="text-center text-lg mb-4">
        <strong>If you are willing to work as AAEC Global partner, please complete the following form.</strong>
      </p>
      <blockquote className="text-center text-sm italic mb-6">
        <p>Education providers and agents are welcome to work with us.</p>
      </blockquote>

      <form
        action="/work-with-us/#wpcf7-f2215-p1836-o1"
        method="post"
        noValidate
        className="space-y-6"
      >
        <h4 className="text-xl font-semibold mb-2">Company Details</h4>

        <label className="block">
          Business Name*<br />
          <input
            type="text"
            name="business-name"
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          Address*<br />
          <input
            type="text"
            name="address"
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          City*<br />
          <input
            type="text"
            name="city"
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          State/Country<br />
          <input
            type="text"
            name="state-country"
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          Postcode<br />
          <input
            type="number"
            name="post-code"
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          Country*<br />
          <select
            name="country"
            value={selectedCountry}
            onChange={handleCountryChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            {countryNames.map((country) => (
              <option key={country} value={country}>{country}</option>
            ))}
            <option value="Other">Other</option>
          </select>
          {selectedCountry === 'Other' && (
            <input
              type="text"
              name="other-country"
              value={otherCountry}
              onChange={(e) => setOtherCountry(e.target.value)}
              placeholder="Please specify"
              className="w-full mt-2 p-2 border border-gray-300 rounded-md"
            />
          )}
        </label>

        <label className="block">
          Website*<br />
          <input
            type="url"
            name="website"
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <p className="text-center text-sm mb-4">
          You can also add your Facebook or any other Social Media page of your company.
        </p>

        <label className="block">
          Email*<br />
          <input
            type="email"
            name="email"
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          Company's LinkedIn<br />
          <input
            type="url"
            name="company-linkedin"
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <h4 className="text-xl font-semibold mt-6 mb-2">Main Contact for all Correspondence</h4>

        <label className="block">
          Title*<br />
          <select
            name="title"
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
            <option value="Dr">Dr</option>
            <option value="Prof">Prof</option>
            <option value="Ms">Ms</option>
          </select>
        </label>

        <label className="block">
          First Name*<br />
          <input
            type="text"
            name="first-name"
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          Family Name*<br />
          <input
            type="text"
            name="family-name"
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          Position/Job Title*<br />
          <input
            type="text"
            name="job-title"
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          Email*<br />
          <input
            type="email"
            name="email-185"
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

 
        <label className="block">
          Country*<br />
          <select
            name="country"
            value={selectedCountry}
            onChange={handleCountryChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            {countryNames.map((country) => (
              <option key={country} value={country}>{country}</option>
            ))}
            <option value="Other">Other</option>
          </select>
          {selectedCountry === 'Other' && (
            <input
              type="text"
              name="other-country"
              value={otherCountry}
              onChange={(e) => setOtherCountry(e.target.value)}
              placeholder="Please specify"
              className="w-full mt-2 p-2 border border-gray-300 rounded-md"
            />
          )}
        </label>

        <label className="block">
          Function/Role*<br />
          <select
            name="role"
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            <option value="Admin Support">Admin Support</option>
            <option value="Admissions">Admissions</option>
            <option value="Counsellor">Counsellor</option>
            <option value="Finance">Finance</option>
            <option value="Marketing/Web contact">Marketing/Web contact</option>
            <option value="Sales">Sales</option>
          </select>
        </label>

        <label className="block">
          LinkedIn<br />
          <input
            type="url"
            name="linkedin"
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <button
          type="submit"
          className="w-full bg-[#eb4934] text-white py-2 rounded-md hover:bg-[#d43b2e] transition-colors"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default WorkWithUs;
