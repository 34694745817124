import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AustraliaTabs from './navAustralia';


const StudyInAustralia = () => {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-bold mb-6 text-center">Study in Australia</h1>
      <AustraliaTabs />
      <Outlet />
    </div>
  );
};

export default StudyInAustralia;
