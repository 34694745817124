import React from 'react';

const GREInfo = () => {
    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
            <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">About the GRE Exam</h1>
            <p className="text-gray-700 mb-4">
                The Graduate Record Examination (GRE) is a standardized test that is an admissions requirement for many graduate schools. It measures verbal reasoning, quantitative reasoning, and analytical writing skills.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Test Format</h2>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Analytical Writing: 2 tasks, 30 minutes each</li>
                <li>Verbal Reasoning: 2 sections, 20 questions each</li>
                <li>Quantitative Reasoning: 2 sections, 20 questions each</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Scoring System</h2>
            <p className="text-gray-700 mb-4">
                Scores range from 130 to 170 for each of the Verbal and Quantitative sections, and 0 to 6 for the Analytical Writing section.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">Preparing for the GRE</h2>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Take practice tests to familiarize yourself with the format.</li>
                <li>Work on your time management skills.</li>
                <li>Consider joining a prep course.</li>
            </ul>
        </div>
    );
};

export default GREInfo;
