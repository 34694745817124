import React, { useState } from 'react';
import branches from '../data/branchdata'; // Adjust the path as needed

const ITEMS_PER_PAGE = 6;

const BranchGridHome = () => {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentBranches = branches.slice(startIndex, endIndex);

  // Determine the total number of pages
  const totalPages = Math.ceil(branches.length / ITEMS_PER_PAGE);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="px-4 py-6">
      <h1 className="text-3xl font-bold mb-6 text-center">Branch List</h1>
      <div className="flex flex-wrap gap-10 items-center justify-center">
        {currentBranches.map((branch) => (
          <div
            key={branch.id}
            className="flex flex-col items-center justify-center w-[350px] h-[400px] bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 hover:shadow-xl hover:bg-gray-50 duration-300 ease-in-out"
          >
            <h2 className="text-xl font-semibold mb-2">{branch.name}</h2>
            <p className="text-gray-700 mb-4 text-center">{branch.location}</p>
            <p className="text-gray-700 mb-4 text-center">Manager: {branch.manager}</p>
            <p className="text-gray-700 mb-4 text-center">Phone: {branch.phone}</p>
            <p className="text-gray-700 mb-4 text-center">Email: {branch.email}</p>
            <p className="text-gray-700 mb-4 text-center">{branch.description}</p>
            <a
              href={branch.mapLink}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#eb4934] px-4 py-2 text-white font-bold rounded hover:bg-[#d43b2e] no-underline"
            >
              View on Map
            </a>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-8">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`px-4 py-2 mx-2 rounded ${currentPage === index + 1 ? 'bg-[#eb4934] text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default BranchGridHome;
