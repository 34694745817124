const branches = [
  {
    id: 1,
    name: "Keshab Simkhada",
    location: "ShnakarDev Chowk, Putalisadak, Kathmandu",
    description:
      "A well-established head office located in the heart of Kathmandu. Known for its excellent service and friendly staff.",
    manager: "Manager",
    phone: "01-5348043",
    email: "info@aaecglobal.com",
    mapLink: "https://maps.app.goo.gl/zwpc6EmrM8LoNyct5",
  },
  {
    id: 2,
    name: "Australia Branch",
    location: "Suite 1704, Level 17, 87/89 Liverpool St, Sydney NSW 2000, अस्ट्रेलिया",
    description:
      "Our Australia branch offers a comprehensive range of services with a focus on customer satisfaction.",
    manager: "Kiran Simkhada",
    phone: "",
    email: "kiran@aaecglobal.com",
    mapLink: "https://maps.app.goo.gl/w8qA9hJLvLXR3axv6",
  }
];

export default branches;
