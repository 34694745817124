// src/routes/PrimaryRoutes.jsx
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import WorkWithUs from "../pages/workwithus";
import StudyAbroad from "../scenes/studyabroad/studyAbroadPage";
import OurBranches from "../pages/ourBranches";
import MakeAnEnquiry from "../pages/makeanenquiry";
import Downloads from "../pages/downloads";
import AboutUs from"../pages/aboutUs";
import NotFound from "../components/error";

import StudyAbroadDisplay from "../pages/studyAbroadDisplay";

// Import study abroad routes
import StudyInAustralia from "../scenes/studyabroad/australia/australia";
import OverviewAustralia from "../scenes/studyabroad/australia/overview";
import LifeAtAustralia from "../scenes/studyabroad/australia/life";
import UniversitiesAustralia from "../scenes/studyabroad/australia/universities";
import ScholarshipsAtAustralia from "../scenes/studyabroad/australia/scholarships";
import VisaAndProcessingAustralia from "../scenes/studyabroad/australia/visaandprocessing";
import UkOverview from "../scenes/studyabroad/uk/overview";
import LifeAtUk from "../scenes/studyabroad/uk/life";
import UniversitiesAtUk from "../scenes/studyabroad/uk/universities";
import ScholarshipsAtUk from "../scenes/studyabroad/uk/scholarships";
import VisaAndProcessingUk from "../scenes/studyabroad/uk/visaandprocessing";
import StudyInUK from "../scenes/studyabroad/uk/uk";
import StudyInAmerica from "../scenes/studyabroad/america/america";
import OverviewAmerica from "../scenes/studyabroad/america/overview";
import LifeAtAmerica from "../scenes/studyabroad/america/life";
import UniversitiesAtAmerica from "../scenes/studyabroad/america/universities";
import ScholarshipsAtAmerica from "../scenes/studyabroad/america/scholarships";
import VisaAndProcessingAmerica from "../scenes/studyabroad/america/visaandprocessing";
import StudyInCanada from "../scenes/studyabroad/canada/canada";
import OverviewCanada from "../scenes/studyabroad/canada/overview";
import LifeAtCanada from "../scenes/studyabroad/canada/life";
import UniversitesAtCanada from "../scenes/studyabroad/canada/universities";
import ScholarshipsAtCanada from "../scenes/studyabroad/canada/scholarships";
import VisaAndProcessingCanada from "../scenes/studyabroad/canada/visaandprocessing";
import StudyInEurope from "../scenes/studyabroad/europe/europe";
import OverviewEurope from "../scenes/studyabroad/europe/overview";
import LifeAtEurope from "../scenes/studyabroad/europe/life";
import UniversitiesAtEurope from "../scenes/studyabroad/europe/universities";
import ScholarshipsAtEurope from "../scenes/studyabroad/europe/scholarships";
import VisaAndProcessingEurope from "../scenes/studyabroad/europe/visaandprocessing";
import CountriesEurope from "../scenes/studyabroad/europe/countries";
import StudyInNewZealand from "../scenes/studyabroad/newzealand/newzealand";
import OverviewNewZealand from "../scenes/studyabroad/newzealand/overview";
import LifeAtNewZealand from "../scenes/studyabroad/newzealand/life";
import UniversitiesNewZealand from "../scenes/studyabroad/newzealand/universities";
import ScholarshipsAtNewZealand from "../scenes/studyabroad/newzealand/scholarships";
import VisaAndProcessingNewZealand from "../scenes/studyabroad/newzealand/visaandprocessing";
import IELTSinfo from "../pages/sub-pages/ielts";
import GREInfo from "../pages/sub-pages/gre";
import GMATInfo from "../pages/sub-pages/gmat";
import TOEFLInfo from "../pages/sub-pages/toefl";
import SATInfo from "../pages/sub-pages/sat";
import PTEInfo from "../pages/sub-pages/pte";


function PrimaryRoutes() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/*" element={<Home />} />
      <Route path="/work-with-us" element={<WorkWithUs />} />
      <Route path="/study-abroad/*" element={<StudyAbroad />}>
        <Route index element={<StudyAbroadDisplay />} />
        <Route path="australia" element={<StudyInAustralia />}>
          <Route index element={<OverviewAustralia />} />
          <Route path="life-and-study" element={<LifeAtAustralia />} />
          <Route path="universities" element={<UniversitiesAustralia />} />
          <Route path="scholarships" element={<ScholarshipsAtAustralia />} />
          <Route path="visa-and-processing" element={<VisaAndProcessingAustralia />} />
        </Route>
        <Route path="united-kingdom" element={<StudyInUK />}>
          <Route index element={<UkOverview />} />
          <Route path="life-and-study" element={<LifeAtUk />} />
          <Route path="universities" element={<UniversitiesAtUk />} />
          <Route path="scholarships" element={<ScholarshipsAtUk />} />
          <Route path="visa-and-processing" element={<VisaAndProcessingUk />} />
        </Route>
        <Route path="united-states-of-america" element={<StudyInAmerica />}>
          <Route index element={<OverviewAmerica />} />
          <Route path="life-and-study" element={<LifeAtAmerica />} />
          <Route path="universities" element={<UniversitiesAtAmerica />} />
          <Route path="scholarships" element={<ScholarshipsAtAmerica />} />
          <Route path="visa-and-processing" element={<VisaAndProcessingAmerica />} />
        </Route>
        <Route path="canada" element={<StudyInCanada />}>
          <Route index element={<OverviewCanada />} />
          <Route path="life-and-study" element={<LifeAtCanada />} />
          <Route path="universities" element={<UniversitesAtCanada />} />
          <Route path="scholarships" element={<ScholarshipsAtCanada />} />
          <Route path="visa-and-processing" element={<VisaAndProcessingCanada />} />
        </Route>
        <Route path="europe" element={<StudyInEurope />}>
          <Route index element={<OverviewEurope />} />
          <Route path="countries" element={<CountriesEurope />} />
          <Route path="life-and-study" element={<LifeAtEurope />} />
          <Route path="universities" element={<UniversitiesAtEurope />} />
          <Route path="scholarships" element={<ScholarshipsAtEurope />} />
          <Route path="visa-and-processing" element={<VisaAndProcessingEurope />} />
        </Route>
        <Route path="new-zealand" element={<StudyInNewZealand />}>
          <Route index element={<OverviewNewZealand />} />
          <Route path="life-and-study" element={<LifeAtNewZealand />} />
          <Route path="universities" element={<UniversitiesNewZealand />} />
          <Route path="scholarships" element={<ScholarshipsAtNewZealand />} />
          <Route path="visa-and-processing" element={<VisaAndProcessingNewZealand />} />
        </Route>
      </Route>
      <Route path="/our-branches" element={<OurBranches />} />
      <Route path="/make-an-enquiry" element={<MakeAnEnquiry />} />
      <Route path="/downloads" element={<Downloads />} />
      <Route path="/about-us" element={<AboutUs/>} />
      <Route path="/ielts" element={<IELTSinfo/>}/>
      <Route path="/gre" element={<GREInfo/>}/>
      <Route path="/gmat" element={<GMATInfo/>}/>
      <Route path="/toefl" element={<TOEFLInfo/>}/>
      <Route path="/sat" element={<SATInfo/>}/>
      <Route path="/pte" element={<PTEInfo/>}/>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PrimaryRoutes;