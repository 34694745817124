import React from 'react'
import { Outlet } from 'react-router-dom'
import NewZealandTabs from './navNewZealand'

function StudyInNewZealand() {
    return (
        <div className="max-w-7xl mx-auto p-4">
            <h1 className="text-4xl font-bold mb-6 text-center">Study In New Zealand</h1>            <NewZealandTabs />
            <Outlet />
        </div>
    );
};

export default StudyInNewZealand;